import { createSlice } from "@reduxjs/toolkit";

const headsSlice = createSlice({
    name:"heads",
    initialState: [],
    reducers: {
        addHeads:(state, action) => { return [ ...action.payload]},
        removeHeads:(state) => { return []}
    }
})


export default headsSlice.reducer
export const {addHeads} = headsSlice.actions;