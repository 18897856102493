import React, { useState } from 'react';
import './singup.css'
import { Box, Button, CircularProgress, Input, InputAdornment, LinearProgress, TextField, Container, Grid, Typography, Paper } from '@mui/material';
import { AccountCircle, Height, Password } from '@mui/icons-material';
import { useAppLoginMutation } from '../Services/ApiServices';
import { useDispatch } from 'react-redux';
import { addUser } from '../App/features/userSlice';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@emotion/react';
import Image from "../Assets/nexus light.png"
import useMediaQuery from '@mui/material/useMediaQuery';

const LoginSignupForm = () => {
  const dispatch = useDispatch()
  const theme = useTheme()
  const nav = useNavigate()
  const matches = useMediaQuery(theme.breakpoints.down("md"))
  const [activeForm, setActiveForm] = React.useState('signup');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState(null);
  const [appLogin, {data: data, isLoading, isSuccess, isFetching, isError, error }] = useAppLoginMutation()
  const handleLogin = async () =>{
    setErrorMessage((prev) =>{ return null})
    const data = { email, password }
    const result = appLogin(data).then((response) =>{
        try {
            if(response.data.success){
                sessionStorage.setItem("token",response.data.data.sessionId)
                dispatch(addUser(response.data.data))
                if(response.data.data.role == "TenantAdmin") {
                    nav("/tadminHome")
                }
                if(response.data.data.role == "Admin") {
                    nav("/adminHome")
                }
                if(response.data.data.role == "Manager") {
                    nav("/managerHome")
                }
                if(response.data.data.role == "Head") {
                    nav("/headHome")
                }
                if(response.data.data.role == "SysAdmin")
                {
                  nav("/superAdminHome")
                }
            }
            else {
                setErrorMessage((prev) =>{ return response.data.data.message})
            }
        }
        catch {
            setErrorMessage((prev) =>{ return "Please Try Again"})
        }
    })
  }
  const handleFormSwitch = (form) => {
    setActiveForm(form);
  };
  const formStyle = {
    marginTop:"5%",
    fontSize:"8px",
    textColor:"black"
  }
  const config = matches ? { width:"80%", height:"60%",marginTop:"10%", backgroundColor: theme.palette.background.default, overflow:'hidden'}:{ width:"50%", height:"50%",marginTop:"10%", backgroundColor: theme.palette.background.default, overflow:'hidden'}
  
  return (
    <Box display={"flex"} sx={{ height:"100vh", width:"100%", backgroundColor: theme.palette.background.paper, justifyContent:"center" }}>
      <Paper elevation={"5"}  sx={config} >
      <Grid container sx={{ width:"100%",}}>
        <Grid item xs={12} sm = {12} md={6} lg={6}>
          <Box elevation={0} sx={{ width:"100%",height: matches ? "100%" : "120%", textAlign:"center",backgroundColor:theme.palette.primary.main  }}>
            <div style={{ width:'100%', paddingTop:"15%", }}>
              <img style={{ width:"30%" }} src={Image}/>
            </div>
            <div style={{ width:"100%", paddingBottom:"10%" }}>
            <Typography variant='h3' color={"white"}>Sign In</Typography>
            <Typography variant='h4' color={"white"}>Nexus System</Typography>
            </div>
          </Box>
        </Grid>
        <Grid item xs={12} sm = {12} md={6} lg={6}>
          <Box sx={{ padding:"2%", textAlign:"center", paddingTop:"10%" }}>
            <Typography variant={'h5'} color="primary">Welcome back.</Typography>
          <TextField
              fullWidth
              label="Email"
              type="email"
              style={formStyle}
              value={email}
              size={'small'}
              sx={{
              "& .MuiOutlinedInput-root": {
                color: 'black',
            }}}
              required
              onChange={(e) => setEmail(e.target.value)}
            />
            <TextField
              fullWidth
              
              size='small'
              sx={{
              "& .MuiOutlinedInput-root": {
                color: 'black',
              }}}
              label="Password"
              type="password"
              value={password}
              required
              autoComplete='false'
              style={formStyle}
              onChange={(e) => setPassword(e.target.value)}
            />
          <Button style={{ marginTop:"5%" }} variant='contained' onClick={handleLogin}>Sign In</Button>
          <br/>
          {Boolean(errorMessage) &&  <p style={{ color:"red" }}><b> &#128549; {errorMessage}</b></p>}
          {isLoading && <CircularProgress/>}
          </Box>
        </Grid>
      </Grid>
      </Paper>
    </Box>
          
     
  );
};

export default LoginSignupForm;
  