import { createSlice } from "@reduxjs/toolkit";

const regionSlice = createSlice({
    name:"region",
    initialState: [],
    reducers: {
        addRegion:(state, action) => { return [ ...action.payload]},
        removeRegion:(state) => { return []}
    }
})


export default regionSlice.reducer
export const {addRegion, removeRegion} = regionSlice.actions;