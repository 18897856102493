import { useDispatch } from "react-redux";
import MainSideBar from "../../../Components/SideBar";
import { useEffect, useRef, useState, useCallback } from "react";
import { apiSlice, useGetBranchesQuery } from "../../../Services/ApiServices";
import { addBranch } from "../../../App/features/branchSlice";
import FetchLoading from "../../../Components/Messages/FetchLoading";
import { Button } from "@mui/material";
import { Add, Delete, Edit } from "@mui/icons-material";
import DataGridTable from "../../../Components/Datagrid";
import EditBranch from "./EditBranch";
import DeleteBranch from "./DeleteBranch";
import AssignManager from "./AssignManager";
import CreateBranch from "./CreateBranch";

export default function ViewBranches() {
  const dispatch = useDispatch();
  const [tempList, setTempList] = useState([]);
  const dataGridRef = useRef(null);
  const [modalState, setModalState] = useState({
    brId: "",
    open: false,
    openEdit: false,
    editData: {},
    assignOpen: false,
    assignData: {},
    openDelete: false,
    name: "",
  });
  const [err, setErr] = useState({ error: false, message: "" });
  const [loading, setLoading] = useState(true);
  const { data, refetch, isLoading, error } = useGetBranchesQuery();

  const updater = useCallback(async () => {
    dispatch(apiSlice.util.resetApiState());
    try {
      const res = await refetch({ forceRefetch: true });
      if (res.isError) {
        setLoading(false);
        setErr({ error: true, message: res.error.message || 'An error occurred' });
      } else if (res.isSuccess) {
        dispatch(addBranch(res.data.data.branches));
        setTempList(
          res.data.data.branches.map((item) => ({
            id: item.id,
            placeId: item.placeId,
            branchName: item.name,
            managerName: item.managerName,
            regionName: item.regionName,
            actions: item,
          }))
        );
        if (modalState.assignData.length === 1) {
          setModalState((prev) => ({
            ...prev,
            assignData: res.data.data.branches.filter(
              (item) => item.id === modalState.assignData.id
            ),
          }));
        }
        setLoading(false);
      }
    } catch (err) {
      console.error("Unexpected error:", err);
      setErr({ error: true, message: err.message || 'An unexpected error occurred' });
      setLoading(false);
    }
  }, [dispatch, refetch, modalState.assignData]);

  useEffect(() => {
    updater();
  }, [updater]);

  const handleEditClick = (params) => {
    setModalState((prev) => ({
      ...prev,
      editData: params.formattedValue,
      openEdit: true,
    }));
  };

  const handleDeleteClick = (params) => {
    setModalState((prev) => ({
      ...prev,
      name: params.formattedValue.name,
      brId: params.formattedValue.id,
      openDelete: true,
    }));
  };

  const handleAssignClick = (params) => {
    setModalState((prev) => ({
      ...prev,
      assignData: params.formattedValue,
      assignOpen: true,
    }));
  };

  const columns = [
    {
      field: "branchName",
      headerName: "Branch Name",
      width: 300,
      editable: false,
    },
    {
      field: "managerName",
      headerName: "Manager Name",
      width: 300,
      editable: false,
    },
    {
      field: "regionName",
      headerName: "Region Name",
      width: 250,
      editable: false,
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 300,
      editable: false,
      renderCell: (params) => (
        <>
          <Button color="success" onClick={() => handleEditClick(params)}>
            <Edit />
          </Button>
          <Button color="error" onClick={() => handleDeleteClick(params)}>
            <Delete color="error" />
          </Button>
          <Button
            onClick={() => handleAssignClick(params)}
            variant="outlined"
          >
            Assign Manager
          </Button>
        </>
      ),
    },
  ];

  const addBtn = (
    <Button
      style={{ margin: "2%" }}
      onClick={() => setModalState((prev) => ({ ...prev, open: true }))}
      variant="outlined"
      color="success"
    >
      <Add /> Add Branch
    </Button>
  );

  return (
    <MainSideBar>
      <CreateBranch
        open={modalState.open}
        closer={(open) => setModalState((prev) => ({ ...prev, open }))}
        updater={updater}
      />
      <AssignManager
        open={modalState.assignOpen}
        updater={updater}
        data={modalState.assignData}
        closer={(assignOpen) =>
          setModalState((prev) => ({ ...prev, assignOpen }))
        }
      />
      <DeleteBranch
        handleClose={(openDelete) =>
          setModalState((prev) => ({ ...prev, openDelete }))
        }
        name={modalState.name}
        brId={modalState.brId}
        open={modalState.openDelete}
        updater={updater}
      />
      <EditBranch
        open={modalState.openEdit}
        admin={modalState.editData}
        closer={(openEdit) =>
          setModalState((prev) => ({ ...prev, openEdit }))
        }
        updater={updater}
      />
      {loading ? (
        <FetchLoading />
      ) : err.error ? (
        <div>Error: {err.message}</div>
      ) : (
        <DataGridTable
          data={tempList}
          columns={columns}
          title={"Branches"}
          options={addBtn}
        />
      )}
    </MainSideBar>
  );
}