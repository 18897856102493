import { useDispatch } from "react-redux";
import FetchLoading from "../../../Components/Messages/FetchLoading";
import MainSideBar from "../../../Components/SideBar";
import { apiSlice, useGetManagersQuery } from "../../../Services/ApiServices";
import { useEffect, useState } from "react";
import { addManagers } from "../../../App/features/managerSlice";
import DataGridTable from "../../../Components/Datagrid";
import { Button } from "@mui/material";
import { Add, Delete, Edit } from "@mui/icons-material";
import EditManager from "./EditManager";
import DeleteManager from "./DeleteManager";
import CreateManager from "./CreateManager";

function getFullDate(dateTimeString) {
  try {
    const dateObj = new Date(dateTimeString);
    return dateObj.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
  } catch (error) {
    console.error('Error parsing ISO 8601 date string:', error.message);
    return ''; 
  }
}

export default function ViewManagersList() {
  const [tempList, setTempList] = useState([]);
  const [open, setOpen] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [editData, setEditData] = useState({});
  const [manId, setId] = useState("");
  const [openDelete, setOpenDelete] = useState(false);
  const [name, setName] = useState("");
  const { data, refetch, isLoading, error } = useGetManagersQuery();
  const dispatch = useDispatch();
  const [err, setErr] = useState({ error: false, message: "" });
  const [loading, setLoading] = useState(true);

  const updater = async () => {
    dispatch(apiSlice.util.resetApiState());
    try {
      const response = await refetch({ forceRefetch: true });
      if (response.isError) {
        setErr({ error: true, message: response.error.message || 'An error occurred' });
      } else if (response.isSuccess) {
        dispatch(addManagers(response.data.data.managers));
        setTempList(
          response.data.data.managers.map((item) => ({
            id: item.id,
            firstName: item.firstName,
            lastName: item.lastName,
            email: item.email,
            created: getFullDate(item.createdAt),
            updated: getFullDate(item.updatedAt),
            actions: item,
          }))
        );
      }
    } catch (err) {
      console.error("Unexpected error:", err);
      setErr({ error: true, message: err.message || 'An unexpected error occurred' });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    updater();
  }, []);

  const columns = [
    {
      field: 'firstName',
      headerName: 'First name',
      width: 150,
      editable: false,
    },
    {
      field: 'lastName',
      headerName: 'Last name',
      width: 150,
      editable: false,
    },
    {
      field: 'email',
      headerName: 'Email',
      width: 300,
      editable: false,
    },
    {
      field: 'created',
      headerName: 'Created',
      width: 180,
      editable: false,
    },
    {
      field: 'updated',
      headerName: 'Updated',
      width: 180,
      editable: false,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 180,
      editable: false,
      renderCell: (params) => {
        return (
          <>
            <Button color='success' onClick={() => {
              setEditData(params.formattedValue);
              setOpenEdit(true);
            }}><Edit /></Button>
            <Button color='error' onClick={() => {
              let tempName = `${params.formattedValue.firstName} ${params.formattedValue.lastName}`;
              let tempId = params.formattedValue.id;
              setId(tempId);
              setName(tempName);
              setOpenDelete(true);
            }}><Delete color="error" /></Button>
          </>
        );
      }
    },
  ];

  const addbtn = (
    <Button style={{ margin: "2%" }} onClick={() => setOpen(true)} variant='outlined' color="success">
      <Add /> Add Manager
    </Button>
  );

  if (loading) {
    return <FetchLoading />;
  }

  if (err.error) {
    return <div>Error: {err.message}</div>;
  }

  return (
    <MainSideBar>
      <CreateManager open={open} closer={setOpen} updater={updater} />
      <DeleteManager handleClose={setOpenDelete} manId={manId} updater={updater} name={name} open={openDelete} />
      <EditManager open={openEdit} admin={editData} closer={setOpenEdit} updater={updater} />
      <DataGridTable title={"Managers"} options={addbtn} columns={columns} data={tempList} />
    </MainSideBar>
  );
}