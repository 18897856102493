import ImageGallery from "react-image-gallery";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import "react-image-gallery/styles/css/image-gallery.css";
import { Box } from "@mui/material";

export default function Gallery() {
    const [images, setimages] = useState([])
    const photos = useSelector(state=>state.googleData.photos)
    function replaceUrlValues(url, replacements) {
    const urlParams = new URLSearchParams(url.split("?")[1]);
    for (const [key, value] of Object.entries(replacements)) {
        urlParams.set(key, value);
    }
    return url.split("?")[0] + "?" + urlParams.toString();
    }
    const link = "https://places.googleapis.com/v1/places/ChIJO40v9HWIREcR3mvWm9fc2RI/photos/AelY_Cv8g7dkMl6hAA1-fmVrW3xuluRzmPlb8kfV_j-9yEFdN2oyUrtrWG6ru_zcpoRnh5O7YaUD8hrPUh-eDFsZpgPPfW_VxHbNsZ2hr04WVRnXU-QKBHmO6wTFYMkDbcIw6YEYG5AU1sEQBUyUaXWVsFv_OwjHYPepDseb/media?maxHeightPx=900&maxWidthPx=900&key=AIzaSyBWiQqf-QR374vWY1Y3tmkO7m7WO7RCUfI";
    const thumbnailSize = { maxHeightPx: 100, maxWidthPx: 100 };  
    console.log(photos)
    useEffect(()=>{
        if(photos) {
            let tempLinks = photos.map((item) =>{
                return {
                    original: item,
                    thumbnail: replaceUrlValues(item, thumbnailSize)
                }
            })
            setimages((prev) =>{ return tempLinks})
        }
    }, [photos])
    
    return (
    <>
    <Box sx={{ width:"70%", margin:"1%", padding:"1%"}}>
    <ImageGallery items={images}/>
    </Box>
    </>
    )
}