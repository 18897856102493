import { useEffect, useRef, useState } from "react";
import MainSideBar from "../../../Components/SideBar";
import { useDispatch } from "react-redux";
import { apiSlice, useGetHeadsQuery } from "../../../Services/ApiServices";
import { addHeads } from "../../../App/features/headsSlice";
import { Button } from "@mui/material";
import { Add, Delete, Edit } from "@mui/icons-material";
import FetchLoading from "../../../Components/Messages/FetchLoading";
import DataGridTable from "../../../Components/Datagrid";
import DeleteHead from "./DeleteHead";
import EditHead from "./EditHead";
import CreateHead from "./CreateHead";

function getFullDate(dateTimeString) {
  try {
    const dateObj = new Date(dateTimeString);
    return dateObj.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
  } catch (error) {
    console.error('Error parsing ISO 8601 date string:', error.message);
    return ''; 
  }
}


export default function ViewHeads () {
    const [tempList, setTempList] = useState([])
    const dataGridRef = useRef(null);
    const [open, setOpen] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);
    const [editData, setEditData] = useState({});
    const [openDelete, setOpenDelete] = useState(false);
    const [name, setName] = useState("");
    const [Id, setId] = useState("");
    const dispatch = useDispatch()
    const [err, setErr] = useState({error: false, message: ""})
    const [loading, setLoading] = useState(true)
    const { data, refetch, isLoading, error } = useGetHeadsQuery();

     const updater = async () =>{
      dispatch(apiSlice.util.resetApiState())
      const res = await refetch({ forceRefetch: true }).then((response)=>{
      if(response.isError) {
        setLoading((prev) =>{ return false})
        setErr((prev) => { return {...prev, error: true, message: response}})
      }
      if(response.isSuccess) {
        dispatch(addHeads(response.data.data.heads))
        setTempList((prev) =>{
          return response.data.data.heads.map((item)=>{
            return {
            id: item.id,
            firstName: item.firstName,
            lastName: item.lastName,
            email: item.email,
            created: getFullDate(item.createdAt),
            updated: getFullDate(item.updatedAt),
            actions: item
        }
          })
        })
        setLoading((prev) =>{ return false})
      }
    })
    
  }

  useEffect(()=>{
    updater()
  },[])

const columns = [
  {
    field: 'firstName',
    headerName: 'First name',
    width: 150,
    editable: false,
  },
  {
    field: 'lastName',
    headerName: 'Last name',
    width: 150,
    editable: false,
  },
  {
    field: 'email',
    headerName: 'Email',
    width: 300,
    editable: false,
  },
  {
    field: 'created',
    headerName: 'Created',
    width: 180,
    editable: false,
  },
  {
    field: 'updated',
    headerName: 'Updated',
    width: 180,
    editable: false,
  },  
  {
    field: 'actions',
    headerName: 'Actions',
    width: 180,
    editable: false,
    renderCell: (params) => {
            return (
                <>
                    <Button color='success' onClick={(e)=>
                    {
                      setEditData((prev)=>{return params.formattedValue});
                      setOpenEdit((prev)=>{return true});
                    }}><Edit/></Button>
                    <Button color='error' onClick={(e)=>{
                      let tempName = `${params.formattedValue.firstName} ${params.formattedValue.lastName}`
                      let tempId = params.formattedValue.id
                      setId((prev) => {return tempId})
                      setName((prev) => {return tempName})
                      setOpenDelete((prev) => {return true})
                    }}><Delete color = "error" /></Button>
                </>
            )}
          },  
        ];




    const handleSelectedRowsChange = (selectedRows) => {
      console.log("refffffffff",dataGridRef.current);
      
      selectedRows = selectedRows[selectedRows.length -1]
    console.log('Selected rows:', selectedRows);
    // Process the selected rows (access data using original data and IDs)
  };

  const getId = () =>{
    return Id
  }


    const addBtn = <Button style={{ marginTop:"2%" }} onClick={()=>{setOpen((prev)=>{return true})}} variant='outlined' color="success"><Add/> Add New Head</Button>
    return (
        <MainSideBar>
            { loading  ? <FetchLoading/> : 
            <>
             <CreateHead open={open} closer={setOpen} updater = {updater}/>
             <EditHead open={openEdit} admin={editData} closer={setOpenEdit} updater = {updater}/>
             <DeleteHead handleClose={setOpenDelete} name={name} headid={Id} updater = {updater} open={openDelete} />  
             <DataGridTable data={tempList} columns={columns} title={"Heads"} options={addBtn} />
            </>
        }
        </MainSideBar>
    )
}