import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/SearchSharp';
import Slide from '@mui/material/Slide';
import { Box, Card, CardContent, CardMedia, CircularProgress, Grid, InputBase, TextField } from '@mui/material';
import { useSearchHotelMutation } from '../../../Services/ApiServices';
import { useTheme } from '@emotion/react';
import BranchCreator from './BranchCreator';
import { useState } from 'react';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function CreateBranch({open, closer, updater}) {
  const [cropen, setCrOpen] = useState(false)
  const [formData, setFormData] = useState("")
  const theme = useTheme()
  const [mutuate, {data, isLoading, isError, error}] = useSearchHotelMutation()
  const [textSearch, settextSearch] = React.useState("")
  const [hotels, setHotels] = React.useState([])

  const handleClose = () => {
    closer((prev) =>{ return false})
  };
  const handleSearch = async () =>{
      await mutuate({textQuery: textSearch}).then((response) =>{
        settextSearch((prev) => {return ""})
      })
  } 
  const creatorOpen = (data) =>{
    setFormData((prev) => {return data})
    setCrOpen((prev) => { return true })
  } 
  return (
    <>
    
    <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Search Branch
            </Typography>
          </Toolbar>
        </AppBar>
        <Grid container justifyContent={"center"} spacing={2} style={{ marginTop:"20px",marginLeft:"2%"}}  >
            <div style={{ display:"flex", width:"100%", justifyContent:"center" }}>
            <Box
            display="flex"
            backgroundColor={theme.palette.background.main}
            borderRadius="3px">
            <InputBase
            autoFocus
            required
            margin="dense"
            id="branchName"
            name="name"
            label="Branch Name"
            type="text"
            variant="standard"
            onChange={(e) =>{ settextSearch((prev) => { return e.target.value})}}
            value={textSearch}
            color="primary" 
            sx={{ ml: 2, flex: 1, width:"100%" }} 
            placeholder="Search Places" />
            <IconButton color="primary"  onClick={() => {handleSearch()}} type="button" sx={{ p: 1 }}>
            <SearchIcon color="primary" />
            </IconButton>
            </Box>
            
            
            
            {/* <Grid item xs={10} style={{ marginTop:"-20px" }}> */}
              {/* <TextField
                autoFocus
                required
                margin="dense"
                id="branchName"
                name="name"
                label="Branch Name"
                type="text"
                sx={{ width:"40%" }}
                variant="standard"
                onChange={(e) =>{ settextSearch((prev) => { return e.target.value})}}
                value={textSearch}
                /> */}
            {/* </Grid> */}
            {/* <Grid item xs={2}> */}
                {/* <Button variant='outlined' style={{ height:"100%" }} onClick={() => {handleSearch()}}>
                  <SearchOutlined/>
                </Button> */}
              {/* </Grid> */}
            </div>
          </Grid>
          <BranchCreator open={cropen} data={formData} closer={setCrOpen} updater = {updater} />
          <Grid item xs={12}>
              <div style={{ width:"100%", display:"flex", justifyContent:"center" }}>
                {isLoading ? 
                <CircularProgress style={{ margin:"auto 0" }} /> :
                data && <>
                 <Grid container spacing={2} style={{ margin: "3%",display:"flex", justifyContent:"center" }} >
                {
                  data.data.places.map((d) =>{
                    return <Card onClick={() =>{ creatorOpen({ id: d.id, name: d.displayName})}} sx={{ display: 'flex', bgcolor: 'background.paper', width: '300px', margin: "10px" }} elevation={4}>
                                <CardContent sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
                                  <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                      <CardMedia
                                        component="img"
                                        image={d.imageUrl}
                                        style={{ width: "100%", height: 150, objectFit: 'cover' }}
                                      />
                                      <Typography variant="h6" component="div" style={{ color: theme.palette.text.main }}>
                                        {d.displayName}
                                      </Typography>
                                      <Typography variant="body2" color="text.secondary" style={{ color: theme.palette.text.main }}>
                                        {d.formattedAddress}
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                </CardContent>
                              </Card>
                  })
                }
                </Grid>
                </>
                }
              </div>
          </Grid>
      </Dialog>
 </>
  )


}
