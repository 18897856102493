import { useEffect, useRef, useState } from "react";
import MainSideBar from "../../../Components/SideBar"
import { apiSlice, useGetRegionsQuery } from "../../../Services/ApiServices";
import { useDispatch } from "react-redux";
import { addRegion } from "../../../App/features/regionSlice";
import { Button } from "@mui/material";
import { Add, Delete, Edit } from "@mui/icons-material";
import FetchLoading from "../../../Components/Messages/FetchLoading";
import DataGridTable from "../../../Components/Datagrid";
import EditRegion from "./EditRegion";
import DeleteRegion from "./DeleteRegion";
import CreateRegion from "./CreateRegion";
import AssignHead from "./AssignHead";

export default function ViewRegion() {
    const [tempList, setTempList] = useState([])
    const dataGridRef = useRef(null);
    const [open, setOpen] = useState(false);
    const [assignOpen, setAssignOpen] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);
    const [editData, setEditData] = useState({});
    const [assignData, setAssignData] = useState({});
    const [openDelete, setOpenDelete] = useState(false);
    const [name, setName] = useState("");
    const [Id, setId] = useState("");
    const { data, refetch, isLoading, error } = useGetRegionsQuery();
    const [err, setErr] = useState({error: false, message: ""})
    const [loading, setLoading] = useState(true)
    const dispatch = useDispatch()

     const updater = async () =>{
      dispatch(apiSlice.util.resetApiState())
      const res = await refetch({ forceRefetch: true }).then((response)=>{
      if(response.isError) {
        setLoading((prev) =>{ return false})
        setErr((prev) => { return {...prev, error: true, message: response}})
      }
      if(response.isSuccess) {
        // console.log(response.data.data.regions);
        dispatch(addRegion(response.data.data.regions))
        setTempList((prev) =>{
          return response.data.data.regions.map((item)=>{
            return {
              id: item.id,
              regionName: item.name,
              headName: (item.headName || "UNASSIGNED"),
              actions: item
            }
          })
        })
        setLoading((prev) =>{ return false})
      }
    })
  }

  
  useEffect(() =>{
    updater()
  },[])

  const columns = [
  {
    field: 'regionName',
    headerName: 'Region Name',
    width: 350,
    editable: false,
  },
  {
    field: 'headName',
    headerName: 'Head Name',
    width: 350,
    editable: false,
  },  
  {
    field: 'actions',
    headerName: 'Actions',
    width: 480,
    editable: false,
    renderCell: (params) => {
            return (
                <>
                    <Button color='success' onClick={(e)=>
                    {
                      let tempId = params.formattedValue.id
                      let tempData = {
                        id: params.formattedValue.id,
                        regionName: params.formattedValue.name,
                        regionHead: params.formattedValue.regionHead || "",
                      }
                      
                      setId((prev) => {return tempId})
                      setEditData((prev)=>{return params.formattedValue});
                      setEditData((prev)=>{return tempData});
                      setOpenEdit((prev)=>{return true});
                    }}><Edit/></Button>
                    <Button color='error' onClick={(e)=>{
                      let tempName = params.formattedValue.name
                      let tempId = params.formattedValue.id
                      setId((prev) => {return tempId})
                      setName((prev) => {return tempName})
                      setOpenDelete((prev) => {return true})
                    }}><Delete color = "error" /></Button>
                    <Button
                      onClick={()=>{
                        setAssignData((prev) => { return params.formattedValue})
                        setAssignOpen((prev) => {return true})
                      }}
                      variant='outlined'>
                      Assign Head
                    </Button>
                </>
            )}
          },  
      
        ];
    const addBtn = <Button style={{ marginTop:"2%" }} onClick={()=>{setOpen((prev)=>{return true})}} variant='outlined' color="success"><Add/> Add New Region</Button>
    return (
        <MainSideBar>
           { loading ? <FetchLoading/> : 
           <>
            <AssignHead open={assignOpen} data={assignData} closer={setAssignOpen}  updater = {updater}/>
            <CreateRegion open={open} closer={setOpen}  updater = {updater}/>
            <EditRegion open={openEdit} admin={editData} closer={setOpenEdit} updater = {updater}/>
            <DeleteRegion handleClose={setOpenDelete}  updater = {updater} name={name} headid={Id} open={openDelete} />
            <DataGridTable data={tempList} columns={columns} title={"Regions"} options={addBtn} />
           </>
           }
        </MainSideBar>
    )
}



