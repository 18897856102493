import { createSlice } from "@reduxjs/toolkit";

const nexusDataSlice = createSlice({
    name:"nexusData",
    initialState: {},
    reducers: {
        addNexusData:(state, action) => { return { ...action.payload}},
        removeNexusData:(state) => { return {}}
    }
})


export default nexusDataSlice.reducer
export const {addNexusData, removeNexusData} = nexusDataSlice.actions;