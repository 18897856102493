import { createSlice } from "@reduxjs/toolkit";

const managersSlice = createSlice({
    name:"managers",
    initialState: [],
    reducers: {
        addManagers:(state, action) => { return [ ...action.payload]},
        removeManagers:(state) => { return []}
    }
})


export default managersSlice.reducer
export const {addManagers} = managersSlice.actions;