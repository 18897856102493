import { createSlice } from "@reduxjs/toolkit";

const hotelSlice = createSlice({
    name:"hotel",
    initialState: {},
    reducers: {
        addId:(state, action) => { return {...state, id: action.payload}},
        addHotel:(state, action) => { return { id: state.id, ...action.payload}},
        removeHotel:(state) => { return {}}
    }
})


export default hotelSlice.reducer
export const {addHotel, removeHotel, addId} = hotelSlice.actions;