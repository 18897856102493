import React, { useState } from 'react';
import { Card, CardContent, CardMedia, Typography, IconButton, Grid, Divider } from '@mui/material';
import { PlayArrow, Preview, PreviewOutlined, PreviewRounded, PreviewTwoTone, Visibility, WhereToVote, WhereToVoteRounded, WhereToVoteTwoTone } from '@mui/icons-material';
import { useGetDetailsQuery } from '../Services/ApiServices';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { addId } from '../App/features/hotelDetail';
import Alt from "../Assets/alt.jpg"

const HotelCard = ({ image, theme, id, title, description, getDetails}) => {
  const tenantId = useSelector(state => state.user.tenantId )
  const dispatch = useDispatch()
  const navigater = useNavigate()
  const [loading, setLoading] = useState(false)
  const [opacity, setOpacity] = useState("100%")
  const placeId  = id
  // const { data: detailsData, isLoading, error } = useGetDetailsQuery(placeId, tenantId);
  
  const fetchData = () =>{
    dispatch(addId(placeId))
    navigater(`/details`)
  }

  const cardStyle = { 
    display: 'flex', 
    bgcolor: 'background.paper', 
    margin: "10px", 
    overflow:"hidden",
    minWidth: '23%',
    maxWidth: '23%',
    boxShadow: "2px 2px 5px #5c5e5d",
    transition: 'transform 0.3s ease-in-out',
    ':hover' : {
      boxShadow: "5px 5px 10px #5c5e5d",
      transform: 'scale(1.05)'
    }
   }
      const cardimg = {
    width: "100%", 
    padding:0, 
    height: "auto", 
    objectFit: 'cover',
    transition: 'transform 0.3s ease-in-out, opacity 0.3s ease-in-out',
}

  return (
        <Card 
          onClick={() => fetchData()} sx={{ ...cardStyle }}  >
        <CardContent sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column',  }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <CardMedia
                component="img"
                // image={require("../Assets/test.jpg")}
                image={image}
                // alt={Alt}
                style={cardimg}
              />
              <Typography variant="h6" component="div" style={{ color: theme.palette.text.main }}>
                {title}
              </Typography>
              <Divider/>
              <div style={{ display:"flex" }}>
                <WhereToVote color={"primary"}/> 
                <Typography variant="subtitle2" color="text.secondary" style={{ color: theme.palette.primary.main }}>
                {description}
                </Typography>
              </div>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

  );
};

export default HotelCard;

