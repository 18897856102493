import { createSlice } from "@reduxjs/toolkit";

const adRegionSlice = createSlice({
    name:"adRegion",
    initialState: [],
    reducers: {
        addAdRegions:(state, action) => { return [ ...action.payload]},
        removeAdRegions:(state) => { return []}
    }
})


export default adRegionSlice.reducer
export const {addAdRegions, removeAdRegions} = adRegionSlice.actions;