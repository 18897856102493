import { createSlice } from "@reduxjs/toolkit";

const clientSlice = createSlice({
    name:"client",
    initialState: [],
    reducers: {
        addClientReview:(state, action) => { return [ ...action.payload]},
        removeClientReview:(state) => { return []}
    }
})


export default clientSlice.reducer
export const {addClientReview, removeClientReview} = clientSlice.actions;