import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { apiSlice, useGetNexusAnaysisQuery, useGetGoogleAnaysisQuery, useGetOpenAIAnaysisQuery } from "../Services/ApiServices"
import { addHotel } from "../App/features/hotelDetail"
import MainSideBar from "./SideBar"
import FetchLoading from "./Messages/FetchLoading"
import HotelInformation from "./HotelInfo/HotelInfo"
import { addGoogleData } from "../App/features/googleDataSlice"
import { addNexusData } from "../App/features/nexusDataSlice"
import GoogleData from "./HotelInfo/GoogleData"
import NexusData from "./HotelInfo/NexusData"
import { Grid } from "@mui/material"
import OpenAiData from "./HotelInfo/OpenAIData"

export default function HotelInfoLayout () {
    const dispatch = useDispatch()
    const hotelId = useSelector(state => state.detail.id)
    const glData = useSelector(state => state.googleData)
    const nxData = useSelector(state => state.nexusData)
    const tenantId = useSelector(state => state.user.tenantId)
    const [loading, setLoading] = useState(true)
    const [googleLoading, setGoogleLoading] = useState(true)
    const [openLoading, setOpenLoading] = useState(true)
    const [err, setErr] = useState({error: false, message: ""})
    const {refetch: nexusRefetch, data,  isLoading, isSuccess,isError, error, }= useGetNexusAnaysisQuery(hotelId)
    const {refetch:googleRefetch, data: googleData,  isLoading: googleLoad, isSuccess: googleSuc,isError: googleIsErr, error: googleErr, }= useGetGoogleAnaysisQuery(hotelId)
    const {refetch:openAiRefetch, data: openData,  isLoading: openLoad, isSuccess: openSuc,isError: openIsErr, error: openErr, }= useGetOpenAIAnaysisQuery(hotelId)
    const updater = async (refetch, stoper, adder) =>{
      dispatch(apiSlice.util.resetApiState())
        const obj = {tenant: `${tenantId}` , hotel: `${hotelId}`}
        if(hotelId){
            // console.log("Startedddddddddddddddddd");
            const resp = await refetch({ forceRefetch: true }).then((response)=>{
            stoper((prev) =>{ return false})
            console.log("Resultttttttttttttt ",response);
        if(response.isError) {
            stoper((prev) =>{ return false})
            setErr((prev) => { return {...prev, error: true, message: response}})
        }
        if(response.data) {
            dispatch(adder(response.data.data))
        }
        })

        }
  }
    // useEffect(()=>{
    //     updater(nexusRefetch, setLoading, addNexusData)
    // },[])
    // useEffect(()=>{
    //     updater(googleRefetch, setGoogleLoading, addGoogleData)
    // },[])
    // useEffect(()=>{
    //     updater(openAiRefetch, setOpenLoading)
    // },[])
    return (
        <MainSideBar>
            <>
            <Grid container spacing={3}>
                <Grid item xs={12} md={12} lg={12} xl={12}>
                    <GoogleData/>
                </Grid>
                <Grid item xs={12} md={12} lg={12} xl={12}>
                    <NexusData/>
                </Grid>
                <Grid item xs={12} md={12} lg={12} xl={12}>
                    <OpenAiData/>
                </Grid>
            </Grid>
            {/* <HotelInformation glLoading={googleLoading} nxLoading={loading} /> */}
        {/* loading ? <FetchLoading/> : <HotelInformation/> */}
            {/* {googleLoading ? <FetchLoading/> : <h1>Works Nexus</h1>} */}
            {/* {loading ? <FetchLoading/> : <h1>Works Google</h1>} */}
            {/* {openLoading? <FetchLoading/> : <h1>Works Open AI</h1>}      */}
                </>
        </MainSideBar>
    )
}