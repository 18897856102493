import React, { useEffect, useState } from 'react'
import Grid from '@mui/material/Grid';
import {TextField,Checkbox, Button,Typography,Stack,Chip, Select, MenuItem, Paper, InputLabel,FormControl   } from '@mui/material/';
import { Add, LockOutlined } from '@mui/icons-material';
import Avatar from '@mui/material/Avatar';
import { useDispatch } from 'react-redux';
import { useTheme } from '@emotion/react';
import { useSelector, Provider } from 'react-redux';
import {useNavigate} from "react-router-dom"
import { addToForm, editForm } from '../../../../App/features/formSlice';
import { useSaveReviewAttributesMutation } from '../../../../Services/ApiServices';
import { updateLcForm } from '../../../../App/features/localFormSlice';


const MainFormEditor=({data,disableBtn, setDisable, setTempForm, setDataEdit})=>{
  // const [disableBtn, setDisable] = useState(false)
  const tenantId = useSelector(state=> state.user.tenantId)
  const mainForm = useSelector(state=>state.form)
  const [saveData, { isLoading, error, isSuccess }] = useSaveReviewAttributesMutation();
    const dispatch = useDispatch()
    useEffect(()=>{
    
    setQuestion((prev) => {return data.title})
    if(data.options) {
      setDataOptions((prev)=> { return data.options.split(",")})
      setFormOptions((prev)=> { return data.options.split(",")})
      setAddOptions((prev)=>{return true})
    }
    // setDataOptions((prev)=> { return arrayToCommaSeparatedString(data.options.split())})
    },[data])

//     function arrayToCommaSeparatedString(arr) {
//       if (!Array.isArray(arr)) {
//         return '';
//       }
//       return arr.join(', ');
// }

    const [addOptionsForm, setAddOptions] = React.useState(false)
    const [selectedValue, setSelectedValue] = useState("")
    const [formOptions, setFormOptions] = React.useState([])
    const [dataOptions, setDataOptions] = React.useState()
    const [formItems, setFormItems] = React.useState([])
    const [isChecked, setIsChecked] = React.useState(false);
    const [question, setQuestion] = React.useState(data.question)
    const theme = useTheme()
    const paperStyle={padding :20, margin:"0 auto"}
    const btnstyle={margin:'8px 0'}

    
    const handleDataOptions= (event) => {
        const newInput = event.target.value;
        const newStrings = newInput.split(',').map((value) => value.trim()); // Split by comma, trim spaces
        setFormOptions((prev) => {return newStrings})
        setDataOptions((prev) =>{return newInput}); 
    };
     
    const handleChange = (event) => {
      setIsChecked(event.target.checked);
    };

    const handleSelectOptions = (event) => {
      setSelectedValue((prev)=>{return event.target.value})
      console.log("current state", event.target.value);
      if(event.target.value == "CheckBoxes") {
        setAddOptions(true)
      }
      else if(event.target.value == "DropDown"){
        setAddOptions(true)
      }
      else 
      {
          setAddOptions(false)
          console.log("Updated", addOptionsForm);
      }
  };

  const options = [
    { value: 'CheckBoxes', key: 1 },
    { value: 'DropDown', key: 2 },
    { value: 'Files', key: 3 },
    { value: 'paragraph', key: 4 },
    { value: 'Sentence', key: 5 },
    { value: 'Star Rating', key: 6 },
    ];
    const handleSubmit = async (e) =>{
    e.preventDefault()
    const filter = (type) =>{
      if(type === "DropDown" || type === "CheckBoxes"){
        return formOptions.join(",")
      }
      else {
        return null
      }
    }
    const newItem = {
      id: data.id,
      is_required: isChecked,
      title: question,
      input_type: selectedValue,
      options: filter(selectedValue), // Create a copy of formOptions
    };
    dispatch(updateLcForm(newItem)) 
    setDisable((prev) => {return false}) 
    setQuestion((prev) => {return ""})
    setDataOptions((prev) => {return []})
    setFormOptions((prev) => {return []})
    setSelectedValue((prev) =>{ return ""})
    setIsChecked((prev) => {return false})
    }



    const nav = useNavigate()

    return(
        <form onSubmit={handleSubmit}>
        <Paper style={paperStyle} elevation={5}>
        <Grid container gap={3} alignContent="center">
            <Grid xs={12} align="right">
                    
            </Grid>
            <Grid xs={12}>
                    <h2 color={theme.palette.text.main}>Edit Form</h2>
            </Grid>
            <Grid align="center" xs={12}>
               <TextField type='text' label='Question'
               focused
                title="Not Editable"
               size='small'
                value={question}
                placeholder='Insert Your Question' fullWidth required/>
            </Grid>
            <Grid align="center" xs={12}>
            <FormControl fullWidth required>
            <InputLabel htmlFor="input-type-label">Choose Input Type</InputLabel>
               <Select
               label='Choose Input Type'
               focused
              value={selectedValue}
              size='small'
              onChange={handleSelectOptions}
              labelId="input-type-label">
                {
                    options.map((item, ind) => {
                       return <MenuItem selected={item.value === selectedValue} key={ind} value={item.value}>
                                        {item.value}
                                        </MenuItem>
                    })
                }
              
            </Select>
            </FormControl>
            </Grid>

            {addOptionsForm && <Grid align="center" xs={12}>
               <TextField label='Options' 
                value={dataOptions}
                onChange={handleDataOptions}
            //    value={password}
            //    onChange={(e) => {setPassword((prev) => {return e.target.value})}}
               placeholder='Enter Options' type='text' fullWidth/>
            </Grid>}
            <Grid xs={12}>
                <div style={{ display: 'flex', flexWrap: 'wrap', maxWidth: '400px' }}>
                    {/* <Stack direction="row" spacing={1}> */}
                        {formOptions.map((item, ind) => {
                            if(item !== "") 
                                return <Chip key={ind} label={item} style = {{margin: '2px'  }} color="primary" />
                        })}
                    {/* </Stack> */}
                </div>
            </Grid>
            <Grid xs={12}>
                <Checkbox
                id='req'
                  checked={isChecked}
                  onChange={handleChange}
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />
                <label labelFor = "req">Required</label>
            </Grid>

            {disableBtn && <Button type='submit' color='success' variant="outlined" style={btnstyle} fullWidth> Update Form</Button>}
        </Grid>
        </Paper>
        </form>
    )
}

export default MainFormEditor