import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useDispatch, useSelector } from 'react-redux';
import { apiSlice, useAddRegionMutation, useAssignHeadMutation, useCreateHeadMutation, useGetHeadsQuery, usePostAdminMutation, usePostManagerMutation } from '../../../Services/ApiServices';
import FetchLoading from '../../../Components/Messages/FetchLoading';
import { MessageGenerator, msg, type } from '../../../Components/Messages/MesageGenerator';

import { CircularProgress, FormControl, InputLabel, MenuItem, Select } from '@mui/material';

export default function AssignHead({open, data, closer, updater}) {
//   const [open, setOpen] = useState(false);
const dispatch = useDispatch()
    console.log("senttttttttttt", data);
    const { id, name, tenantId } = data
    const [mutate, { isLoading, isSuccess,isError, error }] = useAssignHeadMutation();
    const [err, setErr] = useState({error: false, message: ""})
    const [loading, setLoading] = useState(true)
    const { data: newData, refetch, isLoading: headLoading, error: headError } = useGetHeadsQuery();
    const [msg, setMsg] = useState({suc: false, message: ""})
    const [headsList, setHeadssList] = useState([])
     const getHeads = async () =>{
      dispatch(apiSlice.util.resetApiState())
      const res = await refetch({ forceRefetch: true }).then((response)=>{
      if(response.isError) {
        setLoading((prev) =>{ return false})
        setErr((prev) => { return {...prev, error: true, message: response}})
      }
      if(response.isSuccess) {
        setHeadssList((prev) =>{
          return response.data.data.heads.map((item)=>{
              return {
            id: item.id,
            firstName: item.firstName,
            lastName: item.lastName,
        }
          })
        })
       
        setLoading((prev) =>{ return false})
      }
    })
    
  }

    useEffect(()=>{
        getHeads()
        }, [data])

  const [formData, setFormData] = useState({
    name: name,
    regionId: id,
    headId: ''
  });
//   const handleChange =async (event) => {
//     setFormData({
//       ...formData,
//       [event.target.name]: event.target.value,
//     });
//   };
  const handleSelect = (event) => {
    setFormData((prev) =>  {return {...prev, headId: event.target.value}})
  };
  

  const handleSubmit = async (event) => {
    event.preventDefault();
    const { regionId, headId } = formData;
    console.log("to senddddddddddddd", { regionId: id, headId});
    const resp = await mutate({ regionId: id, headId}).then((response) =>{
          if(response.data) {
            setMsg((prev) => {return {...prev, suc: true, message: response.data.data.message}})
            updater()
          }
          if(response.error) {
            setErr((prev) =>{ return {...prev, error: true, message: response.error.data.message }})
          }
        })
    updater()
  };
  const handleClose = () =>{
    closer((prev)=>{return false})
    setMsg((prev) =>{ return {...prev, suc: false}})
    setErr((prev) =>{ return {...prev, error: false}})
  }

  return (
    <>
      <Dialog open={open} PaperProps={{ component: 'form' }}>
        <DialogTitle>Assign Head</DialogTitle>
        {<DialogContent>
          <DialogContentText>
            To Assign Head please choose from the list.
          </DialogContentText>
          <TextField
            autoFocus
            required
            margin="dense"
            id="name"
            name="name"
            label="Region Name"
            type="text"
            fullWidth
            variant="standard"
            value={name}
          />
          <FormControl fullWidth required style={{ marginTop: "10px" }}>
            <InputLabel  htmlFor="input-type-label">Region Head</InputLabel>
                <Select
                onChange={handleSelect}
                value={formData.headId}
                name="head"
                required 
                size='small'
                label="regionHead"
                labelId="input-type-label">
                {newData &&
                    headsList.map((item) => {
                        return <MenuItem key={item.id} name={item.id}  value={item.id}>
                    {`${item.firstName} ${item.lastName}`}
                    </MenuItem>
                    })
                }
            </Select>
            </FormControl>
        </DialogContent>}
        <div style={{ display: "flex", justifyContent:"center" }}>
        {isLoading && <CircularProgress style={{ margin:"auto 0" }} />}
        {(msg.suc && !isLoading)  && <MessageGenerator type={type.suc} message={msg.message} />}
        {(err.error && !isLoading) && <MessageGenerator type={type.err} message={err.message} />}
        </div>
        {<DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button type="submit" variant='contained' onClick={handleSubmit}>
            Assign Head
          </Button>
        </DialogActions>}
      </Dialog>
    </>
  );
}
