import { createSlice } from "@reduxjs/toolkit";


const initialState = [];

const localFormSlice = createSlice({
    name: "lcForm",
    initialState: initialState,
    reducers: {
        addToLcForm: (state, action) => {
            return [...action.payload];
        },
        createLcForm: (state, action) => {
            return [...state, action.payload];
        },
        deleteLcForm: (state, action) => {
            return state.filter(i => i.id !== action.payload.id);;
        },
        updateLcForm: (state, action) => {
            return state.map(item => {
                if (item.id === action.payload.id) {
                return {
                    ...item,
                    required: action.payload.required, 
                    title: action.payload.title,
                    inputType: action.payload.inputType,
                    options: action.payload.options
                } 
            }
            else {
                    return item
                }
            })
        },
        removeLcForm: (state) => {
            return [];
        }
    }
});

export default localFormSlice.reducer;
export const { addToLcForm, removeLcForm, deleteLcForm, createLcForm, updateLcForm } = localFormSlice.actions;
