import { createSlice } from "@reduxjs/toolkit";

const adminsSlice = createSlice({
    name:"admin",
    initialState: [],
    reducers: {
        addAdmins:(state, action) => { return [ ...action.payload]},
        removeAdmins:(state) => { return []}
    }
})


export default adminsSlice.reducer
export const {addAdmins, removeAdmins} = adminsSlice.actions;