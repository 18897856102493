import { Box } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { apiSlice, useGetNexusAnaysisQuery } from "../../Services/ApiServices";
import { addNexusData } from "../../App/features/nexusDataSlice";
import LoadingSpinner from "../Messages/LoadingSpinner";
import { useEffect, useState } from "react";
import ChartPage from './LineGraph'; // Assuming LineGraph can handle both line and donut charts
import ApexDonutChartWithDetails from './ApexDonutChartWithDetails'; // Import the donut chart component

export default function NexusData() {
  const dispatch = useDispatch();
  const hotelId = useSelector(state => state.detail.id);
  const [loading, setLoading] = useState(true);
  const [err, setErr] = useState({ error: false, message: "" });
  const { refetch, data, isLoading, isSuccess, isError, error } = useGetNexusAnaysisQuery(hotelId);

  const updater = async () => {
    dispatch(apiSlice.util.resetApiState());
    if (hotelId) {
      const resp = await refetch({ forceRefetch: true }).then((response) => {
        setLoading(false);
        if (response.isError) {
          setErr({ error: true, message: response.error.message });
        }
        if (response.data) {
          dispatch(addNexusData(response.data.data));
        }
      });
    }
  };

  useEffect(() => {
    updater();
  }, []);

  // Extract data for charts
  const reviewsForLineGraph = data?.data?.reviewsForLineGraph || []; // Assuming reviews data for line graph exists
  const sentiments = data?.data?.sentiments || {}; // Extract sentiment data for the donut chart
   console.log(reviewsForLineGraph)
   console.log(sentiments)
  return (
    <Box width={"100%"}>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <> {/* Render both charts conditionally */}
          <ChartPage reviewsForLineGraph={reviewsForLineGraph} />
          {sentiments && <ApexDonutChartWithDetails sentiments={sentiments} />} 
        </>
      )}
    </Box>
  );
}