import { createSlice } from "@reduxjs/toolkit";

const tenantSlice = createSlice({
    name:"tenants",
    initialState: [],
    reducers: {
        addTenants:(state, action) => { return [ ...action.payload]},
        removeTenants:(state) => { return []}
    }
})


export default tenantSlice.reducer
export const {addTenants, removeTenants} = tenantSlice.actions;