import React from 'react';
import Chart from 'react-apexcharts';

const ApexPieChart = ({ sentiments }) => {
  const getSeriesData = (sentimentCategory) => {
    const series = [];
    Object.values(sentimentCategory).forEach((sentimentData) => {
      series.push(sentimentData.totalNoOfReviews);
    });
    return series;
  };

  const getLabels = (sentimentCategory) => {
    const labels = [];
    Object.keys(sentimentCategory).forEach((sentiment) => {
      labels.push(sentiment);
    });
    return labels;
  };

  const calculatePercentage = (count, total) => {
    return ((count / total) * 100).toFixed(2);
  };

  const renderPieCharts = () => {
    return Object.keys(sentiments).map((category) => {
      const sentimentCategory = sentiments[category];
      const series = getSeriesData(sentimentCategory);
      const labels = getLabels(sentimentCategory);
      const totalRatings = series.reduce((acc, val) => acc + val, 0);

      const options = {
        chart: {
          type: 'pie',
        },
        labels: labels,
        tooltip: {
          custom: function({ series, seriesIndex }) {
            const sentiment = labels[seriesIndex];
            const data = Object.values(sentimentCategory)
              .find((d) => labels[seriesIndex] === sentiment);
            const count = series[seriesIndex];
            const percentage = calculatePercentage(count, totalRatings);

            return `<div class="tooltip-content">
              <strong>${sentiment}</strong><br/>
              Count: ${count}<br/>
              ${percentage}% of reviewers gave this rating<br/>
              Summary:<br/>${data ? data.summary : 'No summary available'}
            </div>`;
          },
        },
        legend: {
          position: 'top',
          horizontalAlign: 'center',
        },
        plotOptions: {
          pie: {
            expandOnClick: true,
            dataLabels: {
              style: {
                fontSize: '16px',
                fontWeight: 'bold',
              },
            },
          },
        },
        fill: {
          type: 'gradient',
          gradient: {
            shade: 'dark',
            type: 'vertical',
            gradientToColors: ['#ABE5A1', '#F9D423', '#F64747'],
            stops: [0, 100],
          },
        },
        stroke: {
          show: true,
          width: 2,
          colors: ['#fff'],
        },
        states: {
          hover: {
            filter: {
              type: 'none',
            },
            brightness: 0.8,
          },
          active: {
            filter: {
              type: 'none',
            },
            style: {
              fontSize: '20px',
              fontWeight: 'bold',
              shadow: '0 4px 15px rgba(0, 0, 0, 0.8)',
            },
          },
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: '100%',
              },
              legend: {
                position: 'bottom',
              },
            },
          },
        ],
      };

      return (
        <div key={category} className="chart-container"  style={{ 
          marginTop: '50px',  
          marginBottom: '50px', 
          textAlign: 'center', 
          backgroundColor: 'white', 
          borderRadius: '10px', 
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', 
          padding: '20px',
        }}>
          <h3>{category}</h3>
          <Chart
            options={options}
            series={series}
            type="pie"
            width="1400"
            height="400"
          />
        </div>
      );
    });
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
      {renderPieCharts()}
    </div>
  );
};

export default ApexPieChart;
