import { createSlice } from "@reduxjs/toolkit"

const sessionSlice = createSlice({
    name: "session",
    initialState: {loggedin: false},
    reducers:{
        userLogin: (state) =>{
            return { ...state, loggedin: true}
        },
        userLogout: (state) => {
            return {...state, loggedin: false}
        }
    }
})

export const {userLogin, userLogout} = sessionSlice.actions
export default sessionSlice.reducer