import { useTheme } from "@emotion/react"
import Image from "../Assets/notfound.png"
import { Button } from "@mui/material"
export default function NotFound () {
    const theme = useTheme()
    return (
        <div style={{ width:"100%" }}>
            <div style={{ width:"100%", display:"flex", justifyContent:"center" }}>
                <img style={{ width:"30%" }} src={Image} />
            </div>
            <div style={{width:"100%", display:"flex", justifyContent:"center"}}>
                <h2 style={{ color: theme.palette.primary.main }} ><b>The Page Your Requested is Not Found</b></h2>
            </div>
            <div style={{width:"100%", display:"flex", justifyContent:"center"}}>
                <Button variant="outlined" onClick={() =>{ window.history.go(-1); return false;}} >Go Back</Button>    
            </div>
        </div>
    )
}