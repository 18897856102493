import { useTheme } from '@emotion/react';
import LinearProgress from '@mui/material/LinearProgress';
import {Box} from "@mui/material"

export default function LoadingSpinner() {
    const theme = useTheme()
    return (
        <Box sx={{ width: '100%', marginTop: "5px" }}>
            <LinearProgress style={{ color: theme.palette.primary.main }}/>
        </Box>
    );
}