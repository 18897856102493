import React from 'react';
import ReactFusioncharts from 'react-fusioncharts';
import FusionCharts from 'fusioncharts';
import Charts from 'fusioncharts/fusioncharts.charts';
import FusionTheme from 'fusioncharts/themes/fusioncharts.theme.fusion';

// Load Charts module and theme
Charts(FusionCharts);
FusionTheme(FusionCharts);

const ChartPage = ({ reviewsForLineGraph }) => {
  console.log({ reviewsForLineGraph })
  let chartData = {
    chart: {
      theme: 'fusion',
      caption: 'User Rating Trends',
      subcaption: 'Ratings Over Time',
      xaxisname: 'Date',
      yaxisname: 'Rating',
      showvalues: '0',
      numVisiblePlot: '10',
      scrollheight: '10',
      flatScrollBars: '1',
      scrollShowButtons: '0', // Hide scroll buttons
      scrollColor: '#cccccc',
      showHoverEffect: '1',
      numberSuffix: ' stars',
      plotTooltext: '<b>$label</b><br>Click to view details',
    },
    categories: [],
    dataset: [],
  };

  const handleDateClick = (date) => {
    console.log(`Date clicked: ${date}`);
    // Handle date click if needed
  };

  try {
    // Validate reviewsForLineGraph data before processing
    if (reviewsForLineGraph && reviewsForLineGraph.length > 0) {
      chartData = {
        ...chartData,
        categories: [
          {
            category: reviewsForLineGraph.map((data) => ({
              label: data.published_date.split('T')[0], // Format the date as needed
              link: `javascript:handleDateClick('${data.published_date}')`,
            })),
          },
        ],
        dataset: [
          {
            data: reviewsForLineGraph.map((data) => ({
              value: data.rating,
            })),
          },
        ],
      };
    } else {
      console.error("reviewsForLineGraph is either undefined or empty");
    }
  } catch (error) {
    console.error("Error processing chart data:", error);
    // You can set up a fallback or a message to display in case of error
    chartData = {
      ...chartData,
      caption: 'Error loading chart data',
      subcaption: '',
      categories: [],
      dataset: [],
    };
  }

  return (
    <div>
      <div><h3 style={{ textAlign: 'center', marginBottom: '20px' }}>Line graph</h3> </div>
    <ReactFusioncharts
      type="scrollline2d"
      width="100%"
      height="500"
      dataFormat="json"
      dataSource={chartData}
    />
    </div>
  );
};

export default ChartPage;
