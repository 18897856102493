import { createSlice } from "@reduxjs/toolkit";

const darkModeSlice = createSlice({
    name:"darkMode",
    initialState: {dark: false},
    reducers: {
        onDark:(state) => { return {dark : true}},
        offDark:(state) => { return {dark : false}},
    }
})


export default darkModeSlice.reducer
export const {onDark, offDark} = darkModeSlice.actions;