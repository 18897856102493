import { useEffect, useState } from "react";
import MainSideBar from "../../Components/SideBar";
import { useDispatch, useSelector } from "react-redux";
import { apiSlice, useGetDashboardsQuery } from "../../Services/ApiServices";
import FetchLoading from "../../Components/Messages/FetchLoading";
import { Card, Grid, useTheme } from "@mui/material";
import HotelCard from "../../Components/HotelCard";
import NoData from "../../Components/NoData";
import { addPlace } from "../../App/features/placeSlice";
import { BorderColor } from "@mui/icons-material";
import CustomeCard from "../../Components/CustomeCard";


export default function HeadHome () {
    
    const dispatch = useDispatch()
    const places = useSelector(state=> state.place)
    const name = useSelector(state=> state.user.firstName)
    const theme = useTheme()
    const [err, setErr] = useState({error: false, message: ""})
    const [loading, setLoading] = useState(true)
    const { data, refetch, isLoading, error } = useGetDashboardsQuery();
    const updater = async () =>{
      dispatch(apiSlice.util.resetApiState())
      const res = await refetch({ forceRefetch: true }).then((response)=>{
        setLoading((prev) =>{ return false})
      if(response.isError) {
        setErr((prev) => { return {...prev, error: true, message: response}})
      }
      if(response.isSuccess) {
        console.log("The Data accepted", response.data.data.places);
        dispatch(addPlace(response.data.data.places))
      }
    })
  }
  useEffect(()=>{
    updater()
  },[])
 const cardstyle ={
  display: "flex", 
  height: "350px", 
  borderRadius: "5%", 
  flexDirection: "column", 
  width: "300px",
  BorderColor:"#24524c",
  boxShadow: "4px 7px 10px #24524c"
 }

 const imgStyle = { 
  objectFit: "cover", 
  height:"350px",
  borderRadius: "5%", }

    return(
        <MainSideBar>
          

            <>
    {
      loading ? <FetchLoading/> :
    <>
    {(places.length > 0) ? <Grid container spacing={2} display={"flex"} >
      {
        places.map((item) => {
          // return <CustomeCard
          // theme={theme}
          // key={item.id} 
          // getDetails={()=>{console.log(item.id)}} 
          // title={item.displayName} 
          // description={item.formattedAddress} 
          // image= {item.imageUrl} 
          // id={item.id} 
          // regionId = {item.regionId} />
          return <HotelCard
          theme={theme}
          key={item.id} 
          getDetails={()=>{console.log(item.id)}} 
          title={item.displayName} 
          description={item.formattedAddress} 
          image= {item.imageUrl} 
          id={item.id} 
          regionId = {item.regionId} />
        })
      }
    </Grid> : 
    <NoData message={"No Branches Yet."} />   
  }
  </>
    }
    </>
        </MainSideBar>
    )
}